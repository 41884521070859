<template>
  <div>
    <el-card shadow="never">
      <template #header>
        <div class="clearfix" style="text-align: left">
          <span>品类列表</span>
        </div>
      </template>
      <div class="content">
        <div class="content-header">
          <el-row :gutter="5">
            <el-col :span="8" style="text-align: left">
              <div class="searchblock">
                <!--<el-select v-model="status" style="margin-right: 10px">
                  <el-option label="全部" value="0"></el-option>
                  <el-option label="启用" value="1"></el-option>
                  <el-option label="停用" value="2"></el-option>
                </el-select>-->
                <el-input
                  v-model="keyword"
                  placeholder="输入品类名称搜索"
                  prefix-icon="el-icon-search"
                  class="hidden-sm-and-down"
                ></el-input>

                <el-button type="success" @click="add" style="margin-left: 10px"
                  >添加</el-button
                >
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="content-main">
        <div class="tree-head">
          <div class="tree-head-one">分类名称</div>
          <div class="tree-head-control">操作</div>
        </div>
        <el-tree
          :data="treeTableData"
          ref="treeTable"
          :props="treeDefaultProps"
          :filter-node-method="filterNode"
          default-expand-all
        >
          <template #default="{ data }">
            <div class="tree-custom-node">
              <span class="cate_name">{{ data.Category }}</span>
              <span class="cate_control">
                <el-button
                  v-if="data.Status == 0"
                  type="text"
                  @click="Enable(data.ID, data)"
                  style="margin-right: 20px"
                  >启用</el-button
                >
                <el-button
                  v-if="data.Status == 1"
                  type="text"
                  @click="Disable(data.ID, data)"
                  style="margin-right: 20px"
                  >停用</el-button
                >
                <el-button type="text" size="small" @click.stop="Edit(data.ID)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  :disabled="data.Status == 1 ? true : false"
                  @click.stop="Del(data.ID)"
                  >删除</el-button
                >
              </span>
            </div>
          </template>
        </el-tree>
      </div>
    </el-card>
  </div>
  <el-dialog v-model="editor" title="编辑品类信息" width="30%">
    <el-form
      :model="categoryinfo"
      label-position="left"
      :rules="rule"
      ref="categoryinfo"
    >
      <el-form-item label="上级分类" label-width="100px">
        <el-select
          v-model="categoryinfo.ParentID"
          class="input-size"
          placeholder="请选择"
        >
          <el-option key="0" label="作为顶级分类" value="0"></el-option>
          <el-option
            v-for="ParentID in selectCateData"
            :key="ParentID.ID"
            :label="ParentID.Category"
            :value="ParentID.ID"
            :disabled="ParentID.Leaf == 1 ? true : false"
          >
            <span class="sel_cate_name" :class="'depth_' + ParentID.Depth">{{
              ParentID.Category
            }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="品类名称" label-width="100px" prop="categoryname">
        <el-input v-model="categoryinfo.categoryname"></el-input>
      </el-form-item>
      <el-form-item
        label="芝麻信用参数"
        label-width="100px"
        v-if="categoryinfo.Leaf == 0"
      >
        <el-input v-model="categoryinfo.ZhimaCode"></el-input>
      </el-form-item>
      <el-form-item label="叶子分类" label-width="100px" prop="isleaf">
        <el-radio-group v-model="categoryinfo.Leaf">
          <el-radio-button :label="1">是</el-radio-button>
          <el-radio-button :label="0">否</el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-form-item v-if="categoryinfo.Leaf == 1" label="一级行业" prop="MainClass" label-width="100px" >
        <el-select
          v-model="categoryinfo.MainClass"
          placeholder="请选择"
          @change="getIndustry"
        >
          <el-option
            v-for="item in MainClass"
            :key="item.IndustryCode"
            :label="item.IndustryName"
            :value="item.ID"
          >
            <span class="sel_cate_name">{{ item.IndustryName }}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item v-if="categoryinfo.Leaf == 1" label="二级行业" prop="SubClass" label-width="100px" >
        <el-select v-model="categoryinfo.SubClass" placeholder="请选择">
          <el-option
            v-for="item in SubClass"
            :key="item.IndustryCode"
            :label="item.IndustryName"
            :value="item.ID"
          >
            <span class="sel_cate_name">{{ item.IndustryName }}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="备注" label-width="100px">
        <el-input
          type="textarea"
          rows="4"
          v-model="categoryinfo.remark"
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="CloseDialog('categoryinfo')">取 消</el-button>
        <el-button type="primary" @click="submitform('categoryinfo')"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<style scoped>
.footer {
  margin-top: 50px;
}
.drawer-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.option {
  display: none;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-card /deep/ .el-card__header {
  padding: 10px 15px;
}
.no-margin-bottom {
  margin-bottom: 0px;
}
.mob-header {
  display: none;
}
.searchblock {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.sel_cate_name {
  display: block;
}
.sel_cate_name.depth_1 {
  text-indent: 14px;
}
.sel_cate_name.depth_2 {
  text-indent: 28px;
}
.sel_cate_name.depth_3 {
  text-indent: 42px;
}
.tree-head {
  height: 48px;
  line-height: 48px;
  border: 1px solid #ebeef5;

  display: flex;
  font-weight: bold;
  color: #909399;
  font-size: 14px;
  margin-top: 20px;
}

.tree-head .tree-head-one {
  padding-left: 8px;
  flex-grow: 1;
}

.tree-head .tree-head-control,
.el-tree .tree-custom-node .cate_control {
  width: 50%;
  padding-right: 8px;
  text-align: right;
}

.el-tree {
  overflow: hidden;
  border-left: 1px solid #ebeef5;
  border-right: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
}

>>> .el-tree-node__content {
  height: 48px;
  border: 1px solid #ebeef5;
  border-bottom: 0;
}

.tree-custom-node {
  height: 100%;
  line-height: 48px;
  font-size: 14px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.el-tree .tree-custom-node .cate_name {
  flex-grow: 1;
}

.cate_form >>> .input-size {
  width: 50%;
}
</style>
<script>
import constant from "@/constant";
import { ElMessage } from "element-plus";
export default {
  data() {
    return {
      treeTableData: [],
      treeDefaultProps: {
        children: "children",
        label: "Category",
      },
      selectCateData: "",
      keyword: "",
      status: "0",
      tabledata: [],
      statuslabel: "停用",
      editor: false,
      categoryinfo: {
        categoryname: "",
        remark: "",
        id: "",
        status: "0",
        ParentID: "",
        Leaf: 0,
        ZhimaCode: "",
        MainClass: "",
        SubClass: "",
      },
      MainClass: [],
      SubClass: [],
      rule: {
        categoryname: [
          {
            required: true,
            message: "请输入品类名称",
            trigger: "blur",
          },
        ],
        MainClass: [
          {
            required: true,
            message: "请选择一级行业",
            trigger: "blur",
          },
        ],
        SubClass: [
          {
            required: true,
            message: "请选择二级行业",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    keyword(val) {
      console.log(this.$refs);
      this.$refs.treeTable.filter(val);
    },
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      console.log(value);
      console.log(data);
      return data.Category.indexOf(value) !== -1;
    },
    CloseDialog(formName) {
      this.editor = false;
      this.$refs[formName].resetFields();
    },
    add() {
      this.categoryinfo.categoryname = "";
      this.categoryinfo.id = "";
      this.categoryinfo.remark = "";
      this.editor = true;
    },
    submitform(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.axios
            .post(constant.save_category_url, this.categoryinfo, {
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
            })
            .then((response) => {
              console.log(response.data);

              const cur_obj = this;
              if (response.data == "OK") {
                cur_obj.editor = false;
                ElMessage.success({
                  type: "success",
                  message: "操作成功",
                  duration: 1000,
                  onClose: function () {
                    cur_obj.$router.go(0);
                  },
                });
              } else if (response.data == "HAVECHILD") {
                ElMessage.error({
                  type: "error",
                  message: "该品类有下级，无法修改成叶子分类",
                  duration: 1500,
                });
              } else {
                ElMessage.error({
                  type: "error",
                  message: "操作失败",
                  duration: 1500,
                });
              }
            });
        } else {
          return false;
        }
      });
    },
    ChangeLabel() {
      if (this.categoryinfo.status == "0") this.statuslabel = "停用";
      if (this.categoryinfo.status == "1") this.statuslabel = "启用";
    },
    Getdate(row, column) {
      var cur_date = row[column.property];
      console.log(cur_date);
      return formatDate(cur_date);
      function formatDate(date) {
        var datetime = new Date(date * 1000);
        var year = datetime.getFullYear();
        var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
        var day = ("0" + datetime.getDate()).slice(-2);
        return year + "-" + month + "-" + day;
      }
    },
    Edit(id) {
      if (!id) return false;
      console.log(id);
      let that = this;
      this.axios
        .get(constant.get_category_url, {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            id: id,
          },
        })
        .then((response) => {
          console.log(response);
          that.editor = true;
          that.categoryinfo.categoryname = response.data.Category;
          that.categoryinfo.id = response.data.ID;
          that.categoryinfo.remark = response.data.Remark;
          that.categoryinfo.status = response.data.Status;
          that.categoryinfo.ParentID = response.data.ParentID;
          that.categoryinfo.Leaf = response.data.Leaf;
          that.categoryinfo.ZhimaCode = response.data.ZhimaCode;
          that.categoryinfo.MainClass = response.data.MainClass;
          that.categoryinfo.SubClass = response.data.SubClass;

          that.getIndustry(response.data.MainClass);
        });
    },
    Enable(id, data) {
      this.axios
        .get(constant.enable_category_url, {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            id: id,
          },
        })
        .then((response) => {
          //console.log(response);
          if (response.data == "OK") {
            //this.$router.go(0);
            console.log(data);
            data.Status = 1;
          } else {
            ElMessage.error({
              type: "error",
              message: "操作失败",
            });
          }
        });
      return false;
    },
    Disable(id, data) {
      this.axios
        .get(constant.disable_category_url, {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            id: id,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data == "OK") {
            console.log(data);
            data.Status = 0;
          } else if (response.data == "HAVEMODEL") {
            ElMessage.error({
              type: "error",
              message: "存在关联该品类的型号，不可停用",
            });
          } else if (response.data == "HAVEPDT") {
            ElMessage.error({
              type: "error",
              message: "存在关联该品类的商品，不可停用",
            });
          } else {
            ElMessage.error({
              type: "error",
              message: "操作失败",
            });
          }
        });
      return false;
    },
    Del(id) {
      this.$confirm("确定要删除该品类么？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .get(constant.del_category_url, {
              headers: {
                "Content-Type": "application/json",
              },
              params: {
                id: id,
              },
            })
            .then((response) => {
              console.log(response.data);
              const cur_obj = this;
              if (response.data == "OK") {
                ElMessage.success({
                  type: "success",
                  message: "删除成功",
                  duration: 1000,
                  onClose: function () {
                    cur_obj.$router.go(0);
                  },
                });
              } else if (response.data == "ISENABLE") {
                ElMessage.error({
                  type: "error",
                  message:
                    "删除失败，请先删除该品类的叶子分类，并确认该品类下没有产品再进行删除操作",
                });
              }
            });
        })
        .catch(() => {
          return false;
        });
    },
    init() {
      console.log(this.status);
      this.axios
        .get(constant.get_category_url, {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            status: this.status,
            keyword: this.keyword,
          },
        })
        .then((response) => {
          console.log(response);
          this.tabledata = response.data;
        });
    },

    getIndustry(parentid) {
      this.loading = true;
      this.axios
        .get(constant.industry, {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            parentid,
          },
        })
        .then((response) => {
          console.log(response.data);
          if (parentid > 0) {
            this.SubClass = response.data;
          } else {
            this.MainClass = response.data;
          }
          this.loading = false;
        });
    },
  },
  created: function () {
    this.axios
      .get(constant.get_category_url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        this.tabledata = response.data;
      });

    this.axios
      .get(constant.get_tree_url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response.data);
        this.selectCateData = response.data;
      });
    /*
      this.axios.get(constant.get_cascader_url,{
            headers:{
                'Content-Type': 'application/json'
            },
        }).then((response)=>{
            console.log(response.data);
            this.treeTableData = response.data;
        });*/

    this.axios
      .get(constant.get_cate_tree_url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response.data);
        this.treeTableData = response.data;
      });

    this.getIndustry(0);
  },
};
</script>